import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

import LoadingFallback from 'ui/loading-fallback/LoadingFallback'

const PageNotFound = lazy(() => import('./screens/PageNotFound'))
const Error404 = lazy(() => import('shared/components/Error404'))

const LiveStreamPage = lazy(() => import('./screens/LiveStreamPage'))

const Routes = () => (
  <Suspense fallback={<LoadingFallback type='live-shopping' />}>
    <Switch>
      <Route exact path='/v/:username/:slug' component={LiveStreamPage} />

      <Route exact path='/404' component={Error404} />
      <Route exact path='*' component={PageNotFound} />
    </Switch>
  </Suspense>
)

export default Routes
