import React, { Component, Fragment } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router, useHistory } from 'react-router-dom'
import { observer, Provider } from 'mobx-react'
import { ToastContainer } from 'react-toastify'
import moment from 'moment'

import 'libs/live-shopping' // order import is important (I18n should be always on the top)
import '../../../styles/live_shopping.scss'

import { initSentry } from 'libs/common/sentry'
import { I18nProvider } from '@elo-kit/components'

import ErrorBoundary from 'ui/error-boundary/ErrorBoundary'

import { DEFAULT_FALLBACK_LOCALE } from 'constants/locale.constants'

import { MetaTags } from 'shared/components/MetaTags'

import stores from './stores'
import Routes from './routes'

import ApiBranch from '../shared/components/api-branch/ApiBranch'

initSentry()

const LiveShoppingMetaTags = ({ sellerStore }) => {
  const history = useHistory()
  return <MetaTags pathname={history.location.pathname} sellerStore={sellerStore} />
}

@observer
class LiveShopping extends Component {
  componentDidMount() {
    moment.locale(I18n?.locale || DEFAULT_FALLBACK_LOCALE)
  }
  render() {
    return (
      <I18nProvider>
        <Provider {...stores}>
          <Router>
            <Fragment>
              <LiveShoppingMetaTags sellerStore={stores.sellerStore} />
              <Routes />
              <ApiBranch />
            </Fragment>
          </Router>
          <ToastContainer />
        </Provider>
      </I18nProvider>
    )
  }
}

const root = createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <LiveShopping />
  </ErrorBoundary>
  // </React.StrictMode>
)
