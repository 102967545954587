import currenciesStore from 'shared/stores/currencies.store'
import sellerStore from 'shared/stores/seller.store'
import liveShoppingStore from 'shared/stores/liveShopping.store'

import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { TrackingCodesStore } from 'shop/stores/trackingCodes.store'

import { ApiClient } from 'utils/requests.utils'
import { ApiClientV2 } from 'utils/api-client/api-client.utils'

import liveShoppingEventsStore from './stores/liveShoppingEvents.store'

export default {
  sellerStore,
  currenciesStore,
  liveShoppingEventsStore,
  liveShoppingStore,
  trackingCodesStore: new TrackingCodesStore(new ShopRootStore(new ApiClient(), new ApiClientV2({}))), // TODO: create separate store for live-shopping and remove it
}
