import { action, computed, observable, makeObservable } from 'mobx'

import { sortByPosition } from '@elo-kit/utils/helpers.utils'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/liveShoppingEvents.api'

class LiveShoppingEventsStore extends SharedStore {
  storeName = 'LiveShoppingEventsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  @observable publishedOffer = null
  @observable isChatActive = true

  constructor() {
    super()

    makeObservable(this)
  }

  @computed get publishedOffers() {
    return (this.item.offers || [])
      .filter((item) => (this.item.publishedOffers || []).includes(item.id))
      .sort(sortByPosition)
  }

  @action toggleChat = () => {
    this.isChatActive = !this.isChatActive
  }

  @action setPublishedOffer = (offerId) => {
    if (offerId) {
      this.publishedOffer = (this.item.offers || []).find((item) => String(item.id) === String(offerId))
    } else {
      this.publishedOffer = null
    }
  }
}

export default new LiveShoppingEventsStore()
